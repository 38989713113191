var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"12ca7d6f6344a524c0b01521856f33c85e0a925f"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

import { SENTRY_COMMON_OPTION } from './sentry.common.config';

Sentry.init({
  ...SENTRY_COMMON_OPTION,
  ignoreErrors: ['Linespider'],
  denyUrls: [
    'https://use.typekit.net',
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^app:\/\//i,
  ],
});
